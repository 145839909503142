<template>
  <div class="inner-container">
      <div class="content-container4">
        <div class="progress-container4 mb-8">
          <div class="progress-bar4"></div>
        </div>


        <!-- Hier wird die Klasse aktualisiert -->
        <div>
          <div class="display-1 mb-8 text-center">
            Welche Box möchten Sie haben?
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-2" v-bind="attrs" v-on="on" @click="openModal">mdi-information-outline</v-icon>
              </template>
              <span>Details anzeigen</span>
            </v-tooltip>
          </div>
          <v-row class="align-center justify-center">
            <v-col v-for="box in preDesignedBoxes" :key="box.title" cols="12" md="4">
              <v-card
                  :color="selectedBox && selectedBox.title === box.title ? 'primary' : 'white'"
                  @click="handleBoxClick(box)"
                  class="hoverable-card mb-4"
              >
                <v-img :src="box.image"
                       contain
                       height="300px"
                       width="360px"
                ></v-img>

                <div class="label-container text-center white--text display-1">
                  {{ box.title }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>





          <router-link
            to="/boxauswahl"
            class="text-center d-block mt-10 custom-link secondary--text"
        >
          Box individuell Zusammenstellen
        </router-link>
        <v-dialog v-model="showModal" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Details zu den Boxen</span>
            </v-card-title>
            <v-card-text>
              <p class="modal-intro">
                Entdecken Sie unsere sorgfältig zusammengestellten Boxen. Jede Box wurde mit Bedacht entworfen, um unterschiedlichen Bedürfnissen gerecht zu werden. Ein Blick auf die folgende Liste zeigt Ihnen den Inhalt jeder Box. Falls Sie spezielle Vorlieben oder Bedürfnisse haben, bieten wir Ihnen auch die Möglichkeit, Ihre eigene, individuelle Pflegebox zusammenzustellen. Die Wahl liegt bei Ihnen!
              </p>
              <div v-for="(box, index) in preDesignedBoxes" :key="box.title" :class="{ 'increased-margin': index > 0 }">
                <strong>{{ box.title }}</strong>
                <ul>
                  <li v-for="productId in box.productIds" :key="productId">{{ productIdToTitle(productId) }}</li>
                </ul>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="showModal = false">Schließen</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog v-model="showSelectionDialog" max-width="500px">
          <v-card>
            <v-card-title class="headline" v-if="selectedBox">Box - {{ selectedBox.title }}</v-card-title>
            <v-card-text class="subheading">
              <p>Ausgewählte Produkte:</p>
              <ul>
                <li v-for="(product, index) in selectedProducts" :key="`overview-${index}-${product.id}`">{{ product.title }}</li>
              </ul>


            <v-card v-for="(product, index) in selectedProducts" :key="`product-options-${index}-product.id`" class="product-options pa-2" v-if="product.variantOptions" outlined>
                <!-- Größe -->
                <div class="option-title">
                  <v-icon left>mdi-gesture-tap</v-icon>
                  Größe
                </div>
                <v-chip-group mandatory color="green"  v-model="product.selectedVariant.size" @change="event => updateSelectedVariant(index, event, undefined)" class="chip-group">
                  <v-chip v-for="size in ['S', 'M', 'L', 'XL']" :key="`${index}-${size}`" :value="size" class="chip">
                    <v-icon left>mdi-hand</v-icon>
                    {{ size }}
                  </v-chip>
                </v-chip-group>

                <!-- Material -->
                <div class="option-title mt-4">
                  <v-icon left>mdi-texture</v-icon>
                  Material
                </div>
                <v-chip-group mandatory color="green" v-model="product.selectedVariant.material" @change="event => updateSelectedVariant(index, undefined, event)" class="chip-group mt-2">
                  <v-chip v-for="material in ['Vinyl', 'Nitril', 'Latex']" :key="`${index}-${material}`" :value="material" class="chip">
                    <v-icon left>mdi-flask</v-icon>
                    {{ material }}
                  </v-chip>
                </v-chip-group>
            </v-card>
            </v-card-text>
            <v-card-actions class="dialog-actions pb-4">
              <v-btn color="red" x-large text--white @click="showSelectionDialog = false" style="color: white">Abbrechen</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" x-large @click="proceedToNextStep">Weiter</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </div>
  </div>
</template>



<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import PBStepper from "@/components/PB-Stepper.vue";
import {mapFields} from "vuex-map-fields";


export default {
  components: { PBStepper },
  data: () => ({
    showPopup: false,
    showSelectionDialog: false,
    showModal: false,
    dialogVisible: false, // oder wie auch immer Sie es steuern
    selectedSize: 'M',
    productMapping: {
      'EH': 'Einmalhandschuhe - 100 Stück',
      'F': 'FFP 2 Maske - 1 Stück',
      'M50': 'Mundschutz - 50 Stück',
      'HD': 'Händedesinfektionsmittel - 500ml',
      'FD': 'Flächendesinfektionsmittel - 500ml',
      'ES': 'Schutzschürzen - 100 Stück',
      'B': 'Bettschutz - 25 Stück',
      'WB': 'Wiederverwendbarer Bettschutz - 1 Stück',
      'W': 'Waschlappen - 100 Stück',
      'FI': 'Fingerlinge - 100 Stück',
      // ... andere Produkt-IDs und ihre Titel
    }
}),
  computed: {
    ...mapGetters([
      'totalValue',
    ]),
    ...mapFields([
      'additionalInformation.consultantNumber',
      'system.products',
      'system.preDesignedBoxes',
      'system.selectedProducts',
      'system.selectedBox',
      'system.progress',
      'system.maxProgress',
      'system.minOrderValue',
    ])
  },
  methods: {
    ...mapActions([
      'selectBoxMethod',
      'goToNextStep',
      'updateProductVariant',
    ]),
    ...mapMutations(["increase", "decrease", "selectBox", "updateSelectedVariant", "emptyCart"]),
    getProductVariantsByProductId(id) {
      return this.products.find(p => p.id === id)
    },
    updateSelectedVariant(index, newSize, newMaterial) {
      const product = this.selectedProducts[index];
      if (!product) return;

      // Aktualisieren Sie die Größe oder das Material, falls vorhanden
      let updatedSize = newSize ? newSize : product.selectedVariant.size;
      let updatedMaterial = newMaterial ? newMaterial : product.selectedVariant.material;

      // Generieren Sie die neue ID
      let newId = updatedMaterial.charAt(0).toUpperCase() + updatedSize;

      // Aktualisieren Sie das selectedVariant-Objekt
      let updatedVariant = {
        ...product.selectedVariant,
        id: newId,
        size: updatedSize,
        material: updatedMaterial,
      };

      // Rufen Sie eine Vuex-Mutation oder Aktion auf, um die Varianten zu aktualisieren
      this.$store.dispatch('updateProductVariant', {
        productId: product.id,
        variant: updatedVariant
      });
    },

    productIdToTitle(id) {
      return this.productMapping[id] || 'Unbekanntes Produkt'; // Gibt 'Unbekanntes Produkt' zurück, wenn die ID nicht gefunden wird
    },
    openModal() {
      this.showModal = true;
    },
    selectBoxMethod(box) {
      this.$store.commit('selectBox', box.id);
    },
    handleBoxClick(box) {
      this.selectBoxMethod(box);
      this.showSelectionDialog = true;
    },
    proceedToNextStep() {
      this.showSelectionDialog = false;
      this.$router.push('/bettschutz'); // Ersetzen Sie '/nächsteRoute' durch die tatsächliche Route
      this.$vuetify.goTo(0).then()
    },
  }
};
</script>

<style>

.increased-margin {
  margin-top: 20px;  /* Sie können den Wert nach Bedarf anpassen */
}

.modal-intro {
  font-size: 1.3em;  /* Je nach Vorliebe anpassen */
  margin-bottom: 20px;  /* Abstand zwischen Einleitung und Box-Details */
}

.inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-container4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  /* Dies verteilt den Raum zwischen den Hauptblöcken */
  min-height: 80vh;
  padding: 0 15px;
}

.progress-container4 {
  display: flex;  /* Hinzugefügt, um Flex-Container zu aktivieren */
  width: 40%;
  height: 20px;
  align-items: center;  /* Dies wird den Fortschrittsbalken vertikal zentrieren */
  background-color: #f0f0f0; /* Hintergrundfarbe des Fortschrittsbalkens */
}

.progress-bar4 {
  width: 80%; /* 2 von 5 Schritten -> 40% */
  height: 100%;
  background-color: var(--v-primary-base); /* Primärfarbe des Fortschrittsbalkens */
}

.label-container {
  background-image: linear-gradient(315deg, #f9ad18, #f98918 80%);
  padding: 10px 0;
  font-size: 1.5em;
  width: 100%;
}



/* Hervorhebungsstil für die Karte beim Darüberfahren */
.hoverable-card {
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hoverable-card:hover {
  transform: scale(1.05);  /* Karte vergrößern */
}

.custom-link {
  text-decoration: none;
  font-size: 18px;  /* Sie können die Größe nach Bedarf anpassen */
  transition: color 0.3s ease;  /* Für eine sanfte Farbänderung beim Überfahren */
  margin-top: 0px;
}

.custom-link:hover {
  color: #f98918;  /* Eine Farbe beim Überfahren. Sie können dies an Ihre Vorlieben anpassen */
}

/* Für Mobilgeräte */
@media (max-width: 900px) {
  .content-container4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  /* Dies verteilt den Raum zwischen den Hauptblöcken */
    min-height: 80vh;
    padding: 15px 15px;
  }
}
</style>
