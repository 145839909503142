
declare global {
  interface Window {
    fbq: Function;
    _fbq: any;
  }
}
import {defineComponent} from "vue";
import PBHeader from "@/components/PB-Header.vue";
import PBFooter from "@/components/PBFooter.vue";
import releaseJson from "@/internaldata/release.json"
import {mapFields} from "vuex-map-fields";

export default defineComponent({
  components: {PBFooter, PBHeader},

  data: () => ({
    showPopup: false,
    popupMessage: "",
    germanNames: [
      "Tom", "Anna", "Felix", "Julia", "Lukas", "Sophie", "Max", "Laura", "Leon", "Lena",
      "Paul", "Mia", "Elias", "Sarah", "David", "Emma", "Noah", "Lisa", "Tim", "Marie",
      "Jonas", "Lea", "Ben", "Clara", "Samuel", "Lara", "Moritz", "Hanna", "Anton", "Isabella"
    ],
    germanCities: ["Berlin", "Hamburg", "München", "Köln", "Stuttgart", "Düsseldorf", "Dresden", "Leipzig", "Frankfurt", "Nürnberg"]
  }),

  mounted() {
    if (this.$route.query.berater) {
      this.$store.state.additionalInformation.consultantNumber = this.$route.query.berater
      console.log("consultant was set")
    }

    if (this.$route.query.selectedProvider) {
      this.$store.state.additionalInformation.selectedProvider = this.$route.query.selectedProvider;
      console.log("selectedProvider was set");
    }


    const prefill = this.$route.query.prefill;

    if (this.$vuetify.breakpoint.mdAndUp) {
      // Erstes Popup nach 15 Sekunden
      setTimeout(() => {
        this.showUserActionPopup();

        // Anschließende Popups alle 60 Sekunden
        setInterval(this.showUserActionPopup, 60000);
      }, 15000);
    }




    if (prefill) {
      const jsonString = atob(prefill.toString());
      const storeOverride = JSON.parse(jsonString)
      this.$store.commit("overrideStore", storeOverride)
    } else {
      console.warn("prefill failed object is empty");
    }

    (function(window: Window & typeof globalThis, document: Document, elementType: string, scriptSource: string) {
      if (window.fbq) return; // Existenzprüfung

      // Initialisierung von fbq als Funktion oder als Objekt mit einer Warteschlange, falls noch nicht definiert
      let facebookPixel: any = window.fbq = function() {
        facebookPixel.callMethod
            ? facebookPixel.callMethod.apply(facebookPixel, arguments)
            : facebookPixel.queue.push(arguments);
      };
      if (!window._fbq) window._fbq = facebookPixel;
      facebookPixel.push = facebookPixel;
      facebookPixel.loaded = true;
      facebookPixel.version = '2.0';
      facebookPixel.queue = [];

      // Erstellen eines neuen Skript-Elements für das Facebook-Pixel
      let scriptElement = document.createElement(elementType) as HTMLScriptElement; // Typumwandlung hier
      scriptElement.async = true; // Asynchrones Laden des Skripts
      scriptElement.src = scriptSource; // Quelle des Skripts
      let firstScriptElement = document.getElementsByTagName(elementType)[0];

      if (firstScriptElement.parentNode) { // Überprüfung, ob parentNode existiert
        firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);
      }
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'); // Selbstaufrufende Funktion mit den entsprechenden Parametern



    window.fbq('init', ''); // Setzen Sie hier Ihre eigene Pixel-ID ein
    window.fbq('track', 'PageView');



    },

  methods: {
    fillLocalStoreWithTestData() {
    },

    getRandomFromArray(array: any[]): any {
      return array[Math.floor(Math.random() * array.length)];
    },


    showUserActionPopup() {
      const randomName = this.getRandomFromArray(this.germanNames);
      const userCity = this.getRandomFromArray(this.germanCities);  // Zufällige Stadt aus der Liste auswählen

      this.popupMessage = `${randomName} aus ${userCity} hat soeben ein Hilfsmittelantrag abgeschlossen`;
      this.showPopup = true;

      // Popup nach 5 Sekunden ausblenden
      setTimeout(() => {
        this.showPopup = false;
      }, 5000);
    }



  },

  computed: {
    ...mapFields([""]),

    release() {
      return releaseJson
    },

    isLocal() {
      return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    }
  }
})

