<template>

    <div class="header-content">

      <!-- Logo- und Button-Bereich für Mobile -->
      <div v-if="isMobileDevice" class="mobile-container">
        <router-link to="/pflegegrad">
          <img height="40" :src="logoUrl" alt="Logo" class="mobile-logo" />
        </router-link>
        <v-btn
            class="white--text call-btn mobile-call-btn"
            @click="callConsultation"
            color="homeButton"
            medium
        >
          <v-img src="https://www.docdorado.de/media/00/f3/eb/1695925419/icons8-telefon-100 (1).png" alt="Telefon Icon" class="call-icon" />
          Fachberatung unter: +49 171 7801749
        </v-btn>
      </div>

      <!-- Logo für Desktop -->
      <router-link to="/pflegegrad" v-if="!isMobileDevice">
        <img height="80" :src="logoUrl" alt="Logo" />
      </router-link>

      <span class="header-announcement" v-if="$vuetify.breakpoint.lgAndUp">
        Über 30.000 PFLEGEBEDÜRFTIGE vertrauen uns ihre Versorgung an!
      </span>


      <!-- ... Ihr anderer Code ... -->
      <v-spacer v-if="!isOnline"/>
      <v-icon v-if="!isOnline" color="error">mdi-wifi-remove</v-icon>
      <span class="ml-2 error--text" v-if="!isOnline">Kein Internet</span>
      <v-spacer />

      <v-btn v-if="!isMobileDevice"
             class="white--text call-btn mr-4"
             @click="goToNewLink"
             color="homeButton"
             x-large
      >
        Zurück zur Startseite
      </v-btn>

      <v-btn v-if="!isMobileDevice"
             class="white--text call-btn"
             @click="callConsultation"
             color="homeButton"
             x-large
      >
        <v-icon class="call-icon">mdi-phone</v-icon>
        Fachberatung unter: +49 171 7801749
      </v-btn>


    </div>

</template>

<!-- ... Ihr anderer Code ... -->

<style scoped>
/* ... Ihre anderen Styles ... */

.mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>


<script>
import headerInfo from "@/indivconfig/headerInfo.json";
import { useOnline } from '@vueuse/core'

export default {
  methods: {
    goToLink(link) {
      window.location.href = link;
    },
    goToNewLink() {
      window.location.href = this.newLink; // Verwendung des neuen Links
    },
    callConsultation() {
      window.location.href = "tel:+491717801749";
    },
  },
  name: 'PB-Header',
  data: () => ({
    logoUrl: headerInfo.logoUrl,
    newLink: 'https://www.topajo.com/',
    phoneNumber: headerInfo.phoneNumber,
    homeSiteLink: headerInfo.url,
    isMobileDevice: /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }),

  computed: {
    isOnline(){
      return useOnline()?.value
    }
  }
}
</script>

<style scoped>
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 20px;
  background-color: white;
}

.mobile-logo {
  height: 45px; /* Reduzierte Größe für das Logo */
  margin-bottom: 10px;
}

.header-announcement {
  font-size: 1.5em;
  font-weight: bold;
  text-decoration: underline;
  color: black;
  margin-left: 5%;
}

.mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Stellen Sie sicher, dass es die gesamte Breite des Containers einnimmt */
}

.call-btn {
  font-weight: 600;
  font-size: 1.2em;
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
}

.call-icon {
  font-weight: 1200;
  width: 25px; /* Reduzierte Größe für das Icon */
  height: 25px;
  margin-right: 8px;
}

.mobile-call-btn {
  margin-top: 5px; /* Etwas weniger Abstand */
  font-size: 0.9em; /* Reduzierte Schriftgröße */
}

@media (max-width: 768px) { /* oder ein anderer Breakpoint, je nachdem was für Sie sinnvoll ist */


  .mobile-logo {
    height: auto; /* Lassen Sie das Logo seine natürliche Größe annehmen und von der Breite bestimmt werden */
    max-width: 50%; /* Dies stellt sicher, dass das Logo nicht zu breit wird */
  }

  .mobile-container {
    align-items: center; /* Horizontal zentrieren */
    text-align: center;
  }

  .header-content > .mobile-container {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .mobile-container > router-link {
    display: block;
    text-align: center;
  }


  .call-icon {
    width: 20px;
    height: 20px;
  }
}


</style>
