import Vue from 'vue'
import Vuex from 'vuex'
import {defaultInsurances} from '@/internaldata/insurcanes'
import {defaultPreDesignedBoxes} from "@/internaldata/PreDesignedBoxes";
import {defaultProducts} from "@/internaldata/DefaultProducts";
import {getField, updateField} from 'vuex-map-fields';
import {StoreState} from "@/models/StoreState";
import {Customer} from "@/models/Customer";
import {countryCodes} from "@/internaldata/countryCodes";
import companyData from "@/indivconfig/companyData.json"
import {StoreOverride} from "@/models/StoreOverride";
import createPersistedState from "vuex-persistedstate"


Vue.use(Vuex)

export default new Vuex.Store<StoreState>({
    plugins: [createPersistedState({
        paths: ['system.requestChain'] //persist requestChain for ever :-)
    })],
    state: {
        system: {
            insurances: defaultInsurances,
            preDesignedBoxes: defaultPreDesignedBoxes,
            products: defaultProducts,
            countryCodes: countryCodes,
            selectedProducts: [],
            selectedBox: undefined,
            minOrderValue: 24,
            progress: 0,
            maxProgress: 40,
            shareToken: "",

            // PWA Settings
            isOffline: false,
            requestChain: []
        },

        additionalInformation: {
            consultantNumber: '',
            internalReferenceId: '',
            ikNumber: companyData.IKnumber,
            supplierNumber: companyData.CustomerNumber,
            selectedProvider: "",
            version: '1.0.0'
        },

        customer: {
            firstDelivery: "",
            careDegree: "1",
            collectiveAddressId: '',
            insuranceType: "Legal",
            patientAddress: {
                title: "",
                firstName: "",
                lastName: "",
                city: "",
                street: "",
                zipCode: ""
            },
            deliveryAddress: {
                title: "",
                firstName: "",
                lastName: "",
                city: "",
                street: "",
                zipCode: ""
            },
            contactAddress: {
                title: "",
                firstName: "",
                lastName: "",
                city: "",
                street: "",
                zipCode: ""
            }
        } as Customer
    },

    getters: {
        totalValue: state => {
            return state.system.selectedProducts.reduce((total, product) => {
                const productInProducts = state.system.products.find(p => p.id === product.id);
                return total + productInProducts!!.value;
            }, 0);
        },
        selectedProducts: (state) => state.system.products.filter(p => p.quantity > 0),
        getField,
    },

    mutations: {
        SET_CARE_DEGREE(state, careDegree) {
            state.customer.careDegree = careDegree;
        },
        resetStore(state) {
            state.customer = {
                firstDelivery: "",
                careDegree: "1",
                patientAddress: {
                    title: "",
                    firstName: "",
                    lastName: "",
                    city: "",
                    street: "",
                    zipCode: ""
                },
                deliveryAddress: {
                    title: "",
                    firstName: "",
                    lastName: "",
                    city: "",
                    street: "",
                    zipCode: ""
                },
                contactAddress: {
                    title: "",
                    firstName: "",
                    lastName: "",
                    city: "",
                    street: "",
                    zipCode: ""
                }
            } as Customer

            state.additionalInformation = {
                consultantNumber: '',
                internalReferenceId: '',
                ikNumber: companyData.IKnumber,
                supplierNumber: companyData.CustomerNumber,
                selectedProvider: "",
                version: '1.0.0'
            }

             state.system = {
                 insurances: defaultInsurances,
                 preDesignedBoxes: defaultPreDesignedBoxes,
                 products: defaultProducts,
                 countryCodes: countryCodes,
                 selectedProducts: [],
                 selectedBox: undefined,

                 minOrderValue: 24,
                 progress: 0,
                 maxProgress: 40,
                 shareToken: "",

                 // PWA Settings
                 isOffline: false,
                 requestChain: state.system.requestChain
             }
        },

        overrideStore(state, storeOverride: StoreOverride) {
            const prefillErrors = validateStoreOverride(storeOverride);
            if (prefillErrors.length === 0) {
                state.additionalInformation = {
                    ...state.additionalInformation,
                    ...storeOverride.additionalInformation,
                }
                state.customer = storeOverride.customer
                // state.additionalInformation.supplierNumber = companyData.CustomerNumber
                // state.additionalInformation.ikNumber = companyData.IKnumber
                console.log("prefill successful")
            } else console.error('prefill failed: ', prefillErrors.join(','))
        },
        overrideFullStore(state, storeState: StoreState) {
            if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
                state = storeState
            }
        },
        selectBox(state, boxId) {
            state.system.selectedProducts = [];
            state.system.products.forEach(product => product.quantity = 0);
            state.system.progress = 0;
            const preDesignedBoxe = state.system.preDesignedBoxes.find(box => box.id === boxId)!!;
            state.system.selectedBox = preDesignedBoxe;

            preDesignedBoxe.productIds.forEach(productId => {
                const product = state.system.products.find(product => product.id === productId)!!;
                product.quantity++;
                state.system.selectedProducts.push(JSON.parse(JSON.stringify(product)));
                state.system.progress += product.value;
            });
        },
        increase(state, id) {
            const product = state.system.products.find(product => product.id === id)!!;
            const incrementValue = id === "WB" ? 4 : 1;
            if (state.system.progress + (product.value * incrementValue) > state.system
                .maxProgress || (id === 8 && product.quantity + incrementValue > 4)) {
                return;
            }
            product.quantity += incrementValue;
            for (let i = 0; i < incrementValue; i++) {
                state.system.selectedProducts.push(JSON.parse(JSON.stringify(product)));
            }
            state.system.progress += product.value * incrementValue;
        },
        decrease(state, id) {
            const product = state.system.products.find(product => product.id === id)!!;
            const decrementValue = id === "WB" ? 4 : 1;
            if (product.quantity - decrementValue < 0) {
                return;
            }
            product.quantity -= decrementValue;
            for (let i = 0; i < decrementValue; i++) {
                let removeIndex = -1;
                state.system.selectedProducts.reduce((acc, selectedProduct, index) => {
                    if (selectedProduct.id === id) removeIndex = index;
                    return acc;
                }, {});
                if (removeIndex !== -1) {
                    state.system.selectedProducts.splice(removeIndex, 1);
                    state.system.progress -= product.value;
                }
            }
        },
        emptyCart(state) {
            state.system.selectedProducts = [];
            state.system.products.forEach(product => product.quantity = 0);
            state.system.progress = 0;
        },
        updateSelectedVariant(state, {
            index,
            size,
            material
        }) {
            // console.log(`input: ${index} ${size} ${material}`)

            const product = state.system.selectedProducts[index];
            let selectedVariant = product.selectedVariant!;

            if (size) selectedVariant.size = size
            if (material) selectedVariant.material = material

            const variant = product.variantOptions!.find(v => selectedVariant.size === v.size && selectedVariant.material === v.material)!;
            // console.log(`variant: ${variant.id} ${variant.size} ${variant.material}`)
            product.selectedVariant = JSON.parse(JSON.stringify(variant));
        },
        updateField
    },

    actions: {
        initialiseStore({commit, state}) {
            if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
                commit('selectBox', "1")
                state.customer = {
                    patientAddress: {
                        title: "Herr",
                        firstName: "Patrick Test",
                        lastName: "lastname " + new Date().toDateString(),
                        countryCode: "+49",
                        phoneNumber: "0123456789",
                        email: "pat@rick.de",
                        street: "str 09",
                        zipCode: "12334",
                        city: "Berlin"
                    },
                    deliveryAddress: {
                        title: "Frau",
                        firstName: "Ton",
                        lastName: "i",
                        street: "str 22",
                        zipCode: "55555",
                        city: "Berlin"
                    },
                    contactAddress: {},
                    birthdate: "2000-01-01",
                    insuranceId: "208",
                    insuranceNumber: "I" + generateRandomNumber(),
                    careDegree: "3",
                    firstDelivery: "2023-07-31",
                    deliveryInterval: "31"
                } as Customer
            }
        },
        updateCustomerData({commit}, data) {
            commit('setCustomerData', data)
        },
        clearCart({commit}) {
            commit('emptyCart');
        },
        updateProductVariant({commit}, {productId, variant}) {
            commit('UPDATE_PRODUCT_VARIANT', {productId, variant});
        },
    },
})

export function validateStoreOverride(storeOverride: any): string[] {
    const errors: string[] = [];

    if (storeOverride === null || storeOverride === undefined) {
        errors.push("should be not empty storeOverride")
        return errors;
    }

    // Validate additionalInformation
    if (!storeOverride.additionalInformation) {
        errors.push('additionalInformation is missing');
    } else {
        const {supplierNumber, version} = storeOverride.additionalInformation;
        if (!supplierNumber) {
            // not important for customer
            // errors.push('additionalInformation.supplierNumber is missing');
        }
        if (!version) {
            // not important for customer
            // errors.push('additionalInformation.version is missing');
        }
    }

    // Validate customer
    if (!storeOverride.customer) {
        errors.push('customer is missing');
    } else {
        const {
            birthdate,
            careDegree,
            insuranceId,
            insuranceNumber,
            firstDelivery,
            deliveryInterval,
            moveToUsDate,
            patientAddress,
            signature
        } = storeOverride.customer;
        if (!birthdate) {
            errors.push('customer.birthdate is missing');
        }
        if (!careDegree) {
            errors.push('customer.careDegree is missing');
        }
        if (!insuranceId) {
            errors.push('customer.insuranceId is missing');
        }
        if (!insuranceNumber) {
            errors.push('customer.insuranceNumber is missing');
        }
        if (!firstDelivery) {
            errors.push('customer.firstDelivery is missing');
        }
        if (!deliveryInterval) {
            errors.push('customer.deliveryInterval is missing');
        }
        if (!moveToUsDate) {
            // errors.push('customer.moveToUsDate is missing');
        }
        if (!patientAddress) {
            errors.push('customer.patientAddress is missing');
        } else {
            const {firstName, lastName, street, city, zipCode} = patientAddress;
            if (!firstName) {
                errors.push('customer.patientAddress.firstName is missing');
            }
            if (!lastName) {
                errors.push('customer.patientAddress.lastName is missing');
            }
            if (!street) {
                errors.push('customer.patientAddress.street is missing');
            }
            if (!city) {
                errors.push('customer.patientAddress.city is missing');
            }
            if (!zipCode) {
                errors.push('customer.patientAddress.zipCode is missing');
            }
        }
        if (!signature) {
            // not important for customer
            // errors.push('customer.signature is missing');
        }
    }

    return errors;
}

export function generateRandomNumber(): string {
    const min = 100000000; // Kleinste 9-stellige Zahl (100 Millionen)
    const max = 999999999; // Größte 9-stellige Zahl (999 Millionen)
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber.toString();
}