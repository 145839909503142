<template>
  <div class="content">
  <div class="footer">
    <ul class="footer-links">
      <li><a href="https://www.topajo.com/impressum">Impressum</a></li>
      <li><a href="https://www.topajo.com/privacy">Datenschutz</a></li>
       </ul>
  </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer {
  background-color: #f5f5f5;
  padding: 20px 0;
  width: 100%;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

.footer-links li {
  display: inline;
  margin: 0 10px;
}

.footer-links a {
  color: #333;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}


</style>
