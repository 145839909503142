<template>
  <v-form ref="form" v-model="valid" lazy-validation class="mt-4">
  <div>

    <div class="content-containerStep2">
      <v-row class="justify-center align-center">
      <!-- Linke Spalte -->
      <v-col cols="12" lg="7" md="7" sm="12" xs="12" class="pa-4">
        <v-row>
          <v-col cols="12" class="mb-4 mt-4">
            <h1 class="text-center primary--text">Ihre Pflegehilfsmittel wurden zusammengestellt</h1>
          </v-col>
        </v-row>

        <v-row class="emmy-section">
          <v-col cols="12" lg="3" md="3" sm="12" xs="12">
            <img src="static/images/Emmy.jpg" alt="Mitarbeiterin Bild" class="responsive-image">
          </v-col>
          <v-col cols="12" lg="9" md="9" sm="12" xs="12" class="emmy-text-container">
            <div class="emmy-text">
              <p><span class="primary--text">Guten Tag,</span> ich heiße Emmy und freue mich Sie kennenzulernen! <strong>Bitte vervollständigen Sie Ihre Kontaktdaten,</strong> damit ich Sie über die Beantragung der Pflegehilfsmittel und der Lieferung auf dem Laufenden halten kann.</p>
            </div>
          </v-col>
        </v-row>

        <v-row class="gender-selection">
          <!-- Erster v-col für Geschlechtsauswahl -->
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <h3>Wählen Sie Ihre Anrede:</h3>
            <v-radio-group v-model="customer.patientAddress.title" row>
              <v-radio label="Herr" value="Herr"></v-radio>
              <v-radio label="Frau" value="Frau"></v-radio>
            </v-radio-group>
          </v-col>

          <!-- Zweiter v-col für die Checkbox zur abweichenden Lieferanschrift -->
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-checkbox v-model="differentDelivery" label="Möchten Sie eine abweichende Lieferanschrift verwenden?" @change="showModal = differentDelivery"></v-checkbox>
          </v-col>
        </v-row>


        <h3 class="mb-3">Patienteninformation:</h3>
        <v-row>

          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field v-model="customer.patientAddress.firstName" :rules="rules.nameRules" label="Vorname" prepend-icon="mdi-account"></v-text-field>
          </v-col>

          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field v-model="customer.patientAddress.lastName" :rules="rules.nameRules" label="Nachname" prepend-icon="mdi-account"></v-text-field>
          </v-col>

        </v-row>

        <v-row>

          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field v-model="customer.patientAddress.email" :rules="rules.emailRules" label="E-mail" prepend-icon="mdi-email"></v-text-field>
          </v-col>

          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field v-model="customer.patientAddress.phoneNumber" :rules="rules.phoneRules"
                          label="Telefonnummer" prepend-icon="mdi-phone"></v-text-field>
          </v-col>

        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex justify-space-between align-center"> <!-- Flexbox-Utility-Klassen -->

            <!-- Zurück Button für sm and down -->

            <!-- Zurück Button für md and up -->
            <v-btn v-if="$vuetify.breakpoint.mdAndUp" @click="goToLastStep()" text outlined x-large>
              Zurück
            </v-btn>

            <!-- Weiter Button für sm and down -->
            <v-btn v-if="$vuetify.breakpoint.smAndDown" color="homeButton" class="text-h6 white--text" @click="goToNextStep()" large>
              Pflegehilfsmittel sichern
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
            <!-- Weiter Button für md and up -->
            <v-btn v-else color="homeButton" class="text-h5 white--text" @click="goToNextStep()" x-large>
              Jetzt kostenlos Pflegehilfsmittel sichern
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>

          </v-col>
        </v-row>


      </v-col>

      <!-- Rechte Spalte -->
      <v-col cols="0" md="5" lg="5" class="d-none d-md-block">
        <img
            src="static/images/Paketauspacken.webp"
            alt="Rechtes Bild"
            class="responsive-image-right"
        />
      </v-col>
    </v-row>





      <v-dialog v-model="showModal" max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Abweichende Lieferanschrift</span>
            <v-spacer></v-spacer>
            <v-icon @click="showModal = false">mdi-close</v-icon>
          </v-card-title>

          <v-card-text>
            <!-- Hier kommen die Input-Felder -->
            <v-row>
              <v-col cols="12" xs="6" sm="6" md="6" lg="5" xl="5">
                <v-text-field v-model="customer.deliveryAddress.firstName" :rules="rules.nameRules" :disabled="!!collectiveAddressId"
                              label="Vorname" outlined/>
              </v-col>
              <v-col cols="12" xs="6" sm="6" md="6" lg="5" xl="5">
                <v-text-field v-model="customer.deliveryAddress.lastName" :rules="rules.nameRules" :disabled="!!collectiveAddressId"
                              label="Nachname" outlined/>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field v-model="customer.deliveryAddress.street" :rules="rules.streetRules" :disabled="!!collectiveAddressId"
                              label="Straße & Hausnummer" outlined/>
              </v-col>
              <v-col cols="12" xs="4" sm="4" md="6" lg="6" xl="2">
                <v-text-field v-model="customer.deliveryAddress.zipCode" :rules="rules.zipRules" :disabled="!!collectiveAddressId"
                              label="Postleitzahl" outlined/>
              </v-col>
              <v-col cols="12" xs="8" sm="8" md="6" lg="6" xl="4">
                <v-text-field v-model="customer.deliveryAddress.city" :rules="rules.cityRules" :disabled="!!collectiveAddressId"
                              label="Ort" outlined/>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="showModal = false">Abbrechen</v-btn>
            <v-btn color="primary" @click="showModal = false">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


    <p-b-snackbar :msg="snackbarMessage"></p-b-snackbar>
    </div>
  </div>
  </v-form>
</template>

<script>
import {mapMutations} from 'vuex';
import PBStepper from "@/components/PB-Stepper.vue";
import PBDatepicker from "@/components/PB-Datepicker.vue";
import moment from "moment";
import PBSnackbar from "@/components/PBSnackbar.vue";
import {mapFields} from 'vuex-map-fields';
import collectiveAddress from '@/indivconfig/collectiveAddress.json'
import releaseJson from "@/internaldata/release.json"
import store from "../system/store";
import {defineComponent} from "vue";
import Vue from "vue";

export default defineComponent({
  name: 'Step2Component',
  components: {PBSnackbar, PBDatepicker, PBStepper},
  data() {
    return {
      differentDelivery: false,
      showModal: false,
      showSnackbar: false,
      snackbarMessage: {},
      menu: false,
      now: moment().format("YYYY-MM-DD"),

      rules: {
        sirRules: [(v) => !!v || ''],
        nameRules: [(v) => !!v || 'Bitte geben Sie Ihren Namen an.'],
        prefixPhoneRules: [(v) => !!v || 'Bitte geben Sie Ihre vorwahl an.'],
        phoneRules: [(v) => !!v || 'Bitte geben Sie Ihre Telefonnummer an.'],
      }
    };
  },

  mounted() {
    if (!this.customer.firstDelivery) this.customer.firstDelivery = moment(moment.now()).format("YYYY-MM-DD").toString()
    if (!this.customer.deliveryInterval) this.customer.deliveryInterval = this.customer.firstDelivery?.match('(\\d{1,2})(?=\\D*$)')[0]
  },

  computed: {
    ...mapFields([
      'customer',
      'customer.collectiveAddressId',
      'additionalInformation.consultantNumber',
      'system.insurances',
      'system.selectedProducts',
      'system.products',
      'system.countryCodes',
    ]),

  },

  methods: {
    ...mapMutations(["increase"]),


    onCheckboxChange() {
      this.increase('WB')
      this.snackbarMessage = {
        text: 'Wir haben dreimal den wiederverwendbaren Bettschutz zu Ihrem Warenkorb hinzugefügt.',
        type: 'success'
      };
    },
    async goToLastStep() {
      this.$router.push('/').then();
      this.$vuetify.goTo(0).then()
    },

    async goToNextStep() {
      const isValid = await this.$refs.form.validate()
      if (!isValid) {
        this.snackbarMessage = {text: 'Bitte füllen Sie alle Pflichtfelder aus.', type: 'warning'};
      } else {
        this.$router.push('/lieferintervall').then()
        this.$vuetify.goTo(0).then()
      }
    }
  },
  created() {
    this.countryCodes.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  },
});
</script>


<style>

.content-containerStep2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh; /* Nehmen Sie die volle Höhe des Viewports */
  padding: 0 20px; /* Einige zusätzliche Polsterung an den Seiten, falls gewünscht */
}

.responsive-image {
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.responsive-image-right {
  max-width: 100%;
  height: auto;
}

.emmy-section {
  display: flex;
  align-items: center; /* Ausrichtung der Elemente in der Mitte */
}

.emmy-text-container {
  display: flex;
  justify-content: center;
  align-items: center; /* Vertikale Zentrierung des Textes */
}

.emmy-text {
  font-size: 1.2em;
}

.gradient-primary {
  background: linear-gradient(315deg, #1e7d44, #1e7d44 80%);
  color: white; /* Setzen Sie eine Farbe für den Text, die gut mit dem Gradienten harmoniert */
}
</style>